export const getColorByRating = (rating) => {
    if (rating == 0) return '#B0BEC5' // нет рейтинга
    if (rating < 20) { //неуд
        return '#EF5350'
    } else if (rating >= 20 && rating < 40) {  // удовлетворительный 
        return '#FFF176'
    } else if (rating >= 40 && rating < 60) { // хороший уровень
        return '#C0CA33'
    } else if (rating >= 60 && rating < 80) { //очень хорогий
        return '#388E3C'
    } else if (rating >= 80) { //отличный
        return '#1B5E20'
    }
}