<template>
    <v-data-table :headers="headers" :items="items" hide-default-footer disable-sort class="mb-5 start_indicators_table">
    </v-data-table>
</template>

<script>
import api from "../../modules/api";
import { getPeriodByDate, getPeriodName } from '../../modules/periods';
export default {
    name: 'Table',
    data() {
        return {
            domains: null,
            domain: 'map_indicators',
            indicators: null,
            config: null,
            values: null,
            headers: [{
                text: 'Название показателя',
                value: 'indicator',
                width: '50%'
            },{
                text: 'Период',
                value: 'period',
                width: '20%'
            },
            {
                text: 'Значение',
                value: 'value',
                width: '30%'
            }]
        };
    },
    methods: {
        async loadDomains() {
            const domains = (await api.get('/domains')).payload;
            this.domains = domains
        },
        async loadIndicatorsValues() {
            const result = await Promise.all(this.indicatorsFetchOptions.map((o) => api.get(o.path, o.querySpec)));
            this.values = result.map((r) => r.payload).flat(1)
        },
        async loadIndicatorsByDomain() {
            this.indicators = (await api.get(`/indicators`,
            {
                domain: this.domain,
                size: 2000,
                disabled: false
            },)).payload;
        },
        getDomainName(code) {
            if(!this.domains) return
            return this.domains?.find((d) => d.code === code)?.description
        }
    },
    computed: {
        indicatorsFetchOptions() {
            return this.indicators?.map((indicator) => {
                const todayDate = new Date();
                const periodCode = indicator.periodSpan && getPeriodByDate(todayDate, indicator.periodSpan);
                return ({
                    path: `indicators/values/${this.domain}/${indicator.id}`,
                    querySpec: {
                        period: periodCode,
                        type: 'VALUE'
                    }
                })
            })
        },
        items() {
            if(!(this.indicators)) return
            if(!(this.values)) return
            return this.indicators.map((indicator) => {
                const value = this.values.find((v) => v?.indicatorId === indicator.id)?.value;
                const date = new Date();
                const periodCode = indicator.periodSpan && getPeriodByDate(date, indicator.periodSpan);
                return ({
                        indicator: indicator.name,
                        domain: indicator.domain || this.getDomainName(this.domain),
                        period: periodCode && getPeriodName(periodCode),
                        value
                    })
                
            })
        }  
    },
    async beforeMount() {
        await this.loadIndicatorsByDomain();
        await this.loadDomains();
        await this.loadIndicatorsValues();
    }
}

</script>

<style >
.start_indicators_table td {
    padding: 7px 16px !important;
}
</style>