<template>
    <div v-cloak>
      <transition appear appear-active-class="fade-enter-active" name="fade">
        <div class="wrap">
          <h1>Интерактивная карта ЦЗН</h1>
          <Table />
          <div class="main-map">
            <div class="main-map-left">
              <div class="main-map-left-wrap">
                <v-select
                  v-model="rating_option"
                  dense
                  filled
                  placeholder="Все"
                  rounded
                  :items="filterOptions"
                  @change="filter($event)"
                >
                  <template slot="selection" slot-scope="data">
                    <div style="margin-bottom: 4px">
                      <v-badge dot :color="data.item.color"></v-badge>
                    </div>
                    <div style="margin-left: 14px">{{ data.item.text }}</div>
                  </template>
                  <template slot="item" slot-scope="data">
                    <div style="margin-bottom: 4px">
                      <v-badge dot :color="data.item.color"></v-badge>
                    </div>
                    <div style="margin-left: 14px">{{ data.item.text }}</div>
                  </template>
                </v-select>
                <highcharts
                  ref="chart"
                  :constructorType="'mapChart'"
                  :options="chartOptions"
                  class="hc"
                ></highcharts>
              </div>
            </div>
            <div class="main-map-right">
              <v-text-field
                dense
                filled
                placeholder="Введите название региона"
                rounded
                v-model="searchStr"
                @input="updateRegionsList"
              ></v-text-field>
              <ul>
                <li
                  v-for="reg in formRegionsRightList"
                  :key="'kgjg' + reg.hc_key"
                >
                  <router-link :to="'/region/' + reg.code">{{
                    reg.name
                  }}</router-link>
                  <span> {{ getCznsCount(reg.code) }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="map-colors">
            <div class="map-color-container">
              <span class="map-color" :style="{backgroundColor: getColorByRating(0)}"></span>
              <span> - рейтинг отсутствует или равен 0</span>
            </div>
            <div class="map-color-container">
              <span class="map-color" :style="{backgroundColor: getColorByRating(5)}"></span>
              <span v-text="'- от 0 до <20'" />
            </div>
            <div class="map-color-container">
              <span class="map-color" :style="{backgroundColor: getColorByRating(25)}"></span>
              <span v-text="' - от 20 до <40'" />
            </div>
            <div class="map-color-container">
              <span class="map-color" :style="{backgroundColor: getColorByRating(55)}"></span>
              <span v-text="' - от 40 до <60'" />
            </div>
            <div class="map-color-container">
              <span class="map-color" :style="{backgroundColor: getColorByRating(77)}"></span>
              <span v-text="' - от 60 до <80'" />
            </div>
            <div class="map-color-container">
              <span class="map-color" :style="{backgroundColor: getColorByRating(86)}"></span>
              <span v-text="' - от 80 и выше'" />
            </div>
          </div>
          <br /><br />
          <div>
            <h4>Ранжированный перечень регионов</h4>
            <table class="rating_table" v-if="!isLoading">
              <tr class="header_table">
                <td colspan="1">№</td>
                <td colspan="7">Регион / ЦЗН</td>
                <td colspan="17" v-if="table_data.some(row => row[3] !== undefined) && table_data.some(row => row[3] !== null)">Рейтинг</td>
                <td colspan="3" v-if="table_data.some(row => row[3] !== undefined) && table_data.some(row => row[3] !== null)">Показатель, {{table_data[0][4]}}</td>
                <td colspan="20" v-else>Рейтинг</td>
              </tr>
              <tr v-for="(row, rowIndex) in table_data" :key="'chart_'+rowIndex">
                <td colspan="1">{{ rowIndex + 1 }}.</td>
                <td colspan="7">{{ getRegionNameByCode(row[2]) }}</td>
                <template>
                  <td colspan="20">
                    <div class="rating_bar" :style="'width: ' + row[1] * 0.9 + '%'" v-if="row[1] > 0">_</div>
                    {{ Math.round(row[1] * 100) / 100 }}
                  </td>
                </template>
              </tr>
            </table>
            <div v-else>Загрузка...</div>
          </div>
          <div class="mt-5">
            <h4>Перечень показателей по которым составлялся рейтинг</h4>
          </div>
          <!-- <router-link to="/czn">Карточка ЦЗН</router-link> -->
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  import api from "../modules/api";
  import { getColorByRating } from '../lib/getColorByRating'
  import { truncateStr } from '../lib/truncateStr'
  import { effectivenessRatings, resultMonitorRatings } from "../modules/result_monitoring/ResultMonitorReports";
  import { getRating, loadConfigRating } from "../modules/OrgMonitor";
  // import { loadConfigsByMonitoringType } from '../modules/Rating2023Config'
  import Table from "../components/table/table.vue";
  // import { getRegions } from '../lib/getRegions'
  
  import rudMap from "@highcharts/map-collection/countries/ru/custom/ru-all-disputed.geo.json";
  
  let global_componentThis;
  
  export default {
    name: "Home",
    components: { Table },
    data() {
      return {
        truncateStr,
        isLoading: true,
        rating_option: "rating_1",
        searchStr: "",
        getColorByRating,
        federal_ratings: null,
        regionsRating: [],
        regionsRatingEffective: [],
        resultMonitorRating: [],
        departmentsList: [],
        colors: [
          {
            color: "#B0BEC5",
            min: 0,
            max: 0,
            value: 0
          }
        ],
        filterOptions: [
          {
            value: "rating_1", 
            text: "Рейтинг по исполнению стандарта организации деятельности органов службы занятости населения в субъектах Российской Федерации", 
            color: '#B0BEC5',
          },
          {
            value: "rating_2", 
            text: "Совокупный рейтинг эффективности деятельности", 
            color: '#F3F6FD',
          },
          {
            value: "rating_3", 
            text: "Интегральный рейтинг результативности оказания государственных услуг", 
            color: '#5574B5',
          }
        ],
        chartOptions: {
          chart: {
            map: rudMap,
            backgroundColor: "#F3F6FD",
          },
          title: {
            text: "",
          },
          mapNavigation: {
            enabled: true,
          },
          legend: {
            enabled: false,
          },
          tooltip: {
            pointFormatter: function () {
              return global_componentThis.pointFormatter(this["hc-key"], this["value"]);
            },
          },
          series: [
            {
              name: "Рейтинг",
              color: "#B0BEC5",
              states: {
                hover: {
                //   color: "#5574B5",
                },
              },
              point: {
                events: {
                  click: function () {
                    global_componentThis.openRegion(this["hc-key"]);
                  },
                },
              },
              cursor: "pointer",
              allAreas: false,
              data: null,
            },
            {
              name: "Совокупный рейтинг",
              color: "#B0BEC5",
              states: {
                hover: {
                //   color: "#5574B5",
                },
              },
              point: {
                events: {
                  click: function () {
                    global_componentThis.openRegion(this["hc-key"]);
                  },
                },
              },
              cursor: "pointer",
              allAreas: false,
              data: null,
            },
            {
              name: "Интегральный рейтинг",
              color: "#B0BEC5",
              states: {
                hover: {
                //   color: "#5574B5",
                },
              },
              point: {
                events: {
                  click: function () {
                    global_componentThis.openRegion(this["hc-key"]);
                  },
                },
              },
              cursor: "pointer",
              allAreas: false,
              data: null,
            }
          ],
        },
      };
    },
    computed: {
        table_data() {
          const data = () => {
            if(this.rating_option === 'rating_1') {
              return this.regionsRating
            }
            if(this.rating_option === 'rating_2') {
              return this.regionsRatingEffective
            }
            if(this.rating_option === 'rating_3') {
              return this.resultMonitorRating
            }
            return []
          }
          return data().filter((d) => this.regions.some((r) => r.code === d[2]))
        },
        formRegionsRightList() {
        let regsList = this.filterRegions('');
        if (this.searchStr != null && this.searchStr.length > 0) {
          let upSearchStr = this.searchStr.toUpperCase();
          return regsList.filter((v) => {
            return v.name.toUpperCase().includes(upSearchStr);
          });
        } else {
          return regsList;
        }
      },
    },
    methods: {
      getRegionNameByCode(code) {
        const region = this.regions.find(r => r.code === code);
        if(!region) console.log(code)
        return region ? region.name : "no data";
      }, 
      async loadConfigRating() {
          const config = await loadConfigRating("org_monitor_rating");
          this.federal_ratings = config?.sections.map(section => {
            return {
              id: section.id,
              title: section.name,
              legend: section.legend,
              dataFunction: getRating.bind(null, section.indicators[0]?.id)
            }
          })

          this.federal_rating = this.federal_ratings?.at(-1);
          const data = await this.federal_rating?.dataFunction();
          this.regionsRating = data.reduce((acc, e) => {
            if(acc.some(a => a[2] === e[2])) {
              return acc
            }
            return [...acc, e]
          }, [])
      },

      isFilterRegion(r, searchStr) {
          return (
            (searchStr == null ||
              searchStr.length === 0 ||
              r.name.toUpperCase().includes(searchStr.toUpperCase()))
          );
        
      },
  
      filterRegions(searchStr) {
        if(searchStr == '') return this.regions
        return this.regions.filter((v) => {
          return this.isFilterRegion(v, searchStr);
        });
      },
  
      pointFormatter(hc_key, rating) {
        const region = this.regions.find(r => r.hc_key === hc_key);
        if (!rating) return `${region.name}: нет рейтинга`;
        if (region) return `${region.name}: ${rating}`;
        return "no data";
      },
  
      openRegion(hc_key) {
        for (let r of this.regions) {
          if (r.hc_key === hc_key) this.$router.push("/region/" + r.code);
        }
      },
  
      filter() {
        this.updateRegionsList();

        //this.yearOption = v
      },
  
      async updateRegionsList() {
        this.isLoading = true;
        if (this.rating_option === "rating_1") {
          this.chartOptions.series[0].data = this.filterRegions(
            this.searchStr
          ).map((v) => {
            const rating = this.regionsRating.find(r => r[2] === v.code)?.[1].toFixed(2);
              return ({["hc-key"]: v.hc_key, value: rating, color: getColorByRating(rating)});
            })
        } else {
          this.chartOptions.series[0].data = [];
        }
        if (this.rating_option === "rating_2") {
          const setData = () => {
              this.chartOptions.series[1].data = this.filterRegions(
              this.searchStr
            ).map((v) => {
              const rating = this.regionsRatingEffective.find(r => r[5] === v.code)?.[1].toFixed(2)
                return ({["hc-key"]: v.hc_key, value: rating, color: getColorByRating(rating)});
            })
          }
          if (this.regionsRatingEffective.length !== 0) { 
            setData() 
          } else {
            const effectivenessRating = await effectivenessRatings.at(-1).dataFunction('region', '2023');
            this.regionsRatingEffective = effectivenessRating.reduce((acc, e) => {
              if(acc.some(a => a[5] === e[5])) {
                return acc
              }
              return [...acc, e]
            }, [])
            setData();
          }
        } else {
          this.chartOptions.series[1].data = [];
        }

        if (this.rating_option === "rating_3") {
          const setData = () => {
            this.chartOptions.series[2].data = this.filterRegions(
              this.searchStr
            ).map((v) => {
              const rating = this.resultMonitorRating.find(r => r[5] === v.code)?.[1].toFixed(2)
              return ({["hc-key"]: v.hc_key, value: rating, color: getColorByRating(rating)});
            })
          }
          if(this.resultMonitorRating.length !== 0) { 
            setData() 
          } else {
            const resultMonitorRating = await resultMonitorRatings.at(-1).dataFunction('region', '2023');
            this.resultMonitorRating = resultMonitorRating.reduce((acc, e) => {
              if(acc.some(a => a[5] === e[5])) {
                return acc
              }
              return [...acc, e]
            }, [])
            setData();
          }
        } else {
          this.chartOptions.series[2].data = [];
        }
        this.isLoading = false;
      },
      async loadCznData() {
        let req = await api.get("/department/getList", {});
        if (req.ok) {
          this.departmentsList = req.payload;
        } else alert("Ошибка загрузки данных");
      },
      getCznsCount(regCode) {
        if (this.departmentsList == null) return 0;
        let cnt = 0;
        for (let c of this.departmentsList) {
          if (c.region.code === regCode) cnt++;
        }
        return cnt;
      },
    },
    watch: {
        regionsRating() {
            this.updateRegionsList();
        }
    },
    async beforeMount() {
      this.updateRegionsList();
      global_componentThis = this;
      await this.loadCznData();
      await this.loadConfigRating();
    },
  };
  </script>
  
  <style lang="scss">
  @import "../styles/main.scss";
  </style>
  
  <style scoped lang="scss">
.map-colors {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}
.map-color {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.map-color-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
.rating_table{
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  td {
      border-bottom: 1px solid #e8e8e9;
    }
}

.rating_bar {
  background-color: #2F80ED;
  color: #2F80ED;
  margin-right: 5px;
  display: inline-block;
}

.rating_table td {
  font-size: 10px;
  padding: 0!important;
}

.rating_table tr {
  padding: 0;
}

.header_table td{
  font-weight: bold;
  font-size:10px;
}

</style>